




























































import Icon from '@/components/ui/icon/Icon.vue';
import { ClientsResponse } from '@/models/dto/responses/clients.response';
import Notify from '@/services/helpers/notify';
import ProjectsModule from '@/store/modules/projects/ProjectsModule';
import { ProjectUser, Team } from '@/typings/domain';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
    components: {
        Icon,
    },
})
export default class ProjectTeamList extends Vue 
{
    @Prop() client: ClientsResponse;

    userAvatar(avatar: string):string
    {
        return avatar ? avatar : '/img/avatar.png'
    }
    deleteUser(payload: {userId: number, projectId: number})
    {
        ProjectsModule.deleteUser(payload).then(() => 
        {
            Notify.success('Пользователь удален');
        })
    }
    get projectTeam()
    {
        const projectTeam: Array<Team> = [];
        for(const key in this.client.team)
        {
            // console.log(i);
            
            projectTeam.push(this.client.team[key])
            const users: ProjectUser[] = [];

            for(const userKey in projectTeam[projectTeam.length - 1].users)
            {
                users.push(projectTeam[projectTeam.length - 1].users[userKey])
            }
            projectTeam[projectTeam.length - 1].users = users
        }
        return projectTeam
    }
}
