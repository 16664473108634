





































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import * as CSS from 'csstype';
import { SelectorBarOption, Range } from '@/components/ui/selector-bar/model';
import DropdownTitle from '@/components/ui/dropdown-title/DropdownTitle.vue';
@Component({
  name: 'SelectorBar',
  components: 
  {
    DropdownTitle,
  },
})
export default class SelectorBar extends Vue {
  @Prop() value: string | number;
  @Prop({default: 4}) maxTabs: number;
  @Prop({ required: true }) options: Array<SelectorBarOption>;
    @Prop({ default: false }) needMore: boolean;
    $refs!: {
        dropdownTitle: HTMLElement
        options: Array<HTMLElement>;
        selectorBar: HTMLElement;
      };
      innerValue: string | number = '';
      backplateStyles: CSS.Properties = {};
      previousSelectedIndex = 0;
      public showMore = true
      beforeMount() {
        this.innerValue = this.value ?? this.options[0].value;
      }
      get selectedOption() {
        return this.options.find(o => o.value === this.innerValue);
      }
      get selectedOptionIndex(): number {
        return this.options.indexOf(this.selectedOption);
      }
      @Emit('change')
      select(index: number) {
        this.previousSelectedIndex = this.selectedOptionIndex;
        this.innerValue = this.options[index].value;
        return this.innerValue;
      }
      @Watch('value')
      onValueChange() {
        this.innerValue = this.value;
        this.calculateBackplateStyles();
      }
      @Watch('$i18n.locale')
      @Watch('innerValue')
      calculateBackplateStyles() {
        this.updateBackplatePosition([this.previousSelectedIndex, this.selectedOptionIndex]);
        setTimeout(() => {
          this.updateBackplatePosition();
        }, 250);
      }
      updateBackplatePosition(range?: Range) {
        let $secondOption: HTMLElement;
        let secondOptionBox: DOMRect;
        range?.sort();
        const currentOptionIndex: number = this.selectedOptionIndex;
        const $option: HTMLElement = this.$refs.options[range ? range[0] : currentOptionIndex];
        const $selectorBar: HTMLElement = this.$refs.selectorBar;
        const optionBox = $option.getBoundingClientRect();
        const selectorBarBox = $selectorBar.getBoundingClientRect();
        if (range) {
          $secondOption = this.$refs.options[range[1]];
          secondOptionBox = $secondOption.getBoundingClientRect();
        } else {
          $secondOption = $option;
          secondOptionBox = optionBox;
        }
        this.backplateStyles = {
          top: `${optionBox.top - selectorBarBox.top - 5}px`,
          bottom: `${selectorBarBox.bottom - secondOptionBox.bottom - 5}px`,
          left: `${optionBox.left - selectorBarBox.left}px`,
          right: `${selectorBarBox.right - secondOptionBox.right}px`,
          opacity: 1,
        };
      }
      
      @Emit('moreSelectChanged')
      moreSelected(optionId: SelectorBarOption)
      {
        const closeRef = this.$refs.dropdownTitle as any
        closeRef.$children[0].close()
        return optionId.value;
      }
      
      mounted() {
        this.calculateBackplateStyles();
        this.innerValue = this.value ?? this.options[0].value;
      }
    }
  