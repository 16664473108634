



















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OptionsMore from '@/components/context-menu/repeatable/OptionsMore.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import AddToTeamPopup from '@/modules/projects/AddToTeamPopUp.vue';
import { Project } from '@/typings/domain';
import ProjectsModule from '@/store/modules/projects/ProjectsModule';
import Notify from '@/services/helpers/notify';
import { IShortUser } from '@/models/user';

@Component({
    components: {
        Icon,
        ContextMenuLink,
        AddToTeamPopup,
        DeleteContext,
        ContextMenuSpawner,
        OptionsMore,
    },
})
export default class ProjectCard extends Vue {
    @Prop() project: Project;

    public deleteContextVisible = false;
    public items = [
        {
            id: 5,
            icon: {
                iconColor: '#fff',
                iconName: 'addtoteam',
            },
            title: 'Добавить в команду',
            value: 'addtoteam',
        },
        {
            id: 6,
            icon: {
                iconColor: '#fff',
                iconName: 'edit',
            },
            title: 'Редактировать',
            value: 'edit',
        },
        {
            id: 7,
            icon: {
                iconColor: '#fff',
                iconName: 'done',
            },
            title: 'Завершить',
            value: 'done',
        },
        {
            id: 8,
            icon: {
                iconColor: '#F76747',
                iconName: 'delete',
            },
            title: 'Удалить навсегда',
            value: 'delete',
        },
    ];
    public teamsLocal = Array<IShortUser>();
    public toggleAddTeamVisible = false;
    public optionHovered = false;
    public projectCardHoverd = false;
    mounted() {
        this.initColor();
    }

    itemClicked(item: any) {
        if (item.value === 'delete') 
        {
            this.deleteProjectClicked();
        }
        if (item.value === 'edit') 
        {
            this.$router.push(`/projects/edit/${this.project.id}`);
        }
        if (item.value === 'addtoteam') 
        {
            this.closeOptions();
            this.$nextTick(() => 
            {
                this.toggleAddTeamVisible = true;
            });
        }
        if (item.value === 'done') 
        {
            this.closeOptions();
            ProjectsModule.completeProject(this.project.id).then(() => 
            {
                Notify.success('Проект завершен')
            })
        }
    }
    showHow()
    {
        console.log('dddd');
    }
    addToTeam(items: Array<{id: number}>) 
    {   
        const mapeddUsers = items.map((el) => el.id)
        ProjectsModule.addUsersToProject({team: mapeddUsers, projectId: this.project.id}).then(() => 
        {
            Notify.success('Пользователи добавлены')
            this.toggleAddTeamVisible = false;
            this.fetchProjects('clients');
            this.fetchProjects('tags');
        })
    }
    async fetchProjects(direction?: string | string[]) {
        await ProjectsModule.fetchProjects({ direction: direction });
    }
    deleteProjectClicked() {
        this.deleteContextToggle();
    }

    deleteProject(id: number) {
        ProjectsModule.deleteProject(id).then(() => {
            Notify.success('Направление удалено');
            Promise.all([
                ProjectsModule.fetchProjects({ direction: 'clients' }),
                ProjectsModule.fetchProjects({ direction: 'tags' }),
            ]);
        });
    }

    deleteContextToggle() {
        this.deleteContextVisible = !this.deleteContextVisible;
    }

    initColor() {
        const projectCard = this.$refs.projectCard as any;
        projectCard.style.border = `1px solid ${this.project.color}`;
        projectCard.style.setProperty('--check-project', `${this.project.color}`);
    }

    toggleOptionVisible(event: PointerEvent) {
        const screenWidth = window.innerWidth;
        this.optionHovered = !this.optionHovered;
    }

    /*
     **  Устанавливает правильную позицию
     **  для меню проектов
     */

    setRightPosition(clickPos: number, currentWidth: number, screenWidth: number) {
        if (clickPos + currentWidth > screenWidth) {
            const optRef = this.$refs.projectOption as any;
            optRef.$el.style.right = '-1px';
        }
    }

    closeOptions() {
        this.optionHovered = false;
    }
}
