import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import StoreModules from '@/store/StoreModules';
import store from '@/store';
import ApiClient from '@/api/api.client';
import { IProjectsModule, ProjectsModuleActions as Actions } from '@/store/modules/projects/IProjectsModule';
import { Client, Project, ProjectTag, Teams } from '@/typings/domain';
import { ClientSettingsResponse, ClientsResponse } from '@/models/dto/responses/clients.response';
import { IClientSettings, IProjectData } from '@/models/newProject';
import { ProjectResponse } from '@/models/dto/responses/project.response';
import { ClientProjects } from '@/models/dto/responses/clientProjects.response';

@Module({
    dynamic: true,
    name: StoreModules.Projects,
    namespaced: true,
    store,
})
class ProjectsModule extends VuexModule implements IProjectsModule 
{
    clients: ClientsResponse[] = [];
    projectClients: ClientsResponse[] = [];
    project: ProjectResponse = null;
    projects: ClientsResponse[] = [];
    projectTeams: Teams[] = [];
    clientSettings: IClientSettings = {} as null
    projectDirections: ProjectTag[] = [];
    clientProjects: ClientsResponse[] = []

    get allClients()
    {   
        return this.clients
    }

    get allProjectDirection()
    {   
        return this.projectDirections
    }

    get allClientProjects()
    {   
        return this.clientProjects
    }

    get allProjects()
    {   
        return this.projects
    }

    get allClientSettings()
    {   
        return this.clientSettings
    }

    get clientProject()
    {
        return this.project
    }

    get allProjectsClients()
    {   
        return this.projectClients
    }

    @Mutation
    clearClients()
    {   
        this.projectClients = []
    }

    @Mutation
    clearProjects()
    {   
        this.projects = []
    }

    @Mutation
    setProjects(projects: ClientsResponse[])
    {   
        this.projects = projects
    }

    @Mutation
    setAllClients(clients: ClientsResponse[])
    {   
        this.clients = clients
    }

    @Mutation
    setProjectDirections(dir: ProjectTag[])
    {   
        this.projectDirections = dir
    }

    @Mutation
    setProjectTeam(teams: Teams[])
    {   
        this.projectTeams = teams
    }
    @Mutation
    setClientProjects(clientProjects: ClientsResponse[])
    {   
        this.clientProjects = clientProjects
    }

    @Mutation
    clearClientProjects()
    {   
        this.clientProjects = []
    }

    @Mutation
    setProjectClients(clients: ClientsResponse[])
    {   
        this.projectClients = clients
    }

    @Mutation
    setClientSettings(clients: IClientSettings)
    {   
        this.clientSettings = clients
    }

    @Mutation
    setProject(project: ProjectResponse)
    {   
        this.project = project
    }

    @Action
    async [Actions.fetchProject](projectId:number)
    {
        const response = await ApiClient.projects.getProject(projectId);
        await this.setProject(response)
    }
    
    @Action
    async [Actions.fetchClientProjects](clientId:number)
    {
        const response = await ApiClient.projects.getClientProjects(clientId);
        await this.setClientProjects(response)
    }

    @Action
    async [Actions.fetchClient](clientId:number)
    {
        const response = await ApiClient.projects.getClient(clientId);
        await this.setClientSettings(response)
    }

    @Action
    async [Actions.updateClient](payload: {clientId: number, clientSettings: IClientSettings})
    {
        await ApiClient.projects.updateClient(payload);
    }

    @Action
    async [Actions.createProjects](projects: Array<IProjectData>)
    {
        await ApiClient.projects.createProjects(projects);
    }

    @Action
    async [Actions.fetchProjectDirections](id: number)
    {
        
        const response = await ApiClient.projects.getProjectDirections(id);
        this.setProjectDirections(response)
    }

    @Action
    async [Actions.editProjects](payload: {project: IProjectData, projetsId: number})
    {   
        await ApiClient.projects.editProjects(payload.project, payload.projetsId);
    }

    @Action
    async [Actions.createClient](client: Client)
    {
        const response = await ApiClient.projects.createClient(client)
    }

    @Action
    async [Actions.fetchAllClients]()
    {
        const response = await ApiClient.projects.getAllClients()
        await this.setAllClients(response)
    }

    @Action
    async [Actions.addUsersToProject](payload: {team: Array<number>, projectId: number})
    {
        const response = await ApiClient.projects.addUsersToProject(payload)
    }

    @Action
    async [Actions.completeProject](id: number)
    {
        const response = await ApiClient.projects.completeProject(id)
    }


    @Action
    async [Actions.fetchProjectTeam](id: number)
    {
        const response = await ApiClient.projects.getProjectTeam(id)
        await this.setProjectTeam(response)
    }

    @Action
    async [Actions.deleteProject](id: number)
    {
        const response = await ApiClient.projects.deleteProject(id)
    }

    @Action
    async [Actions.fetchProjects](filter : any)
    {
        const response = await ApiClient.projects.getAllProjects(filter)
        if(filter.direction === 'clients')
        {
            this.setProjectClients(response)
        }
        else if(filter.direction === 'tags')
        {
            this.setProjects(response)
        }
    }
    @Action
    async [Actions.deleteUser](payload: {userId: number, projectId: number})
    {
        await ApiClient.projects.deleteUserFromProject(payload)
    }
}
export default getModule(ProjectsModule);