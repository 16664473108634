import { ClientsResponse } from '@/models/dto/responses/clients.response';
import { Project } from '@/typings/domain';

export interface IProjectsModule {
  	clients: ClientsResponse[]
}

export enum ProjectsModuleActions {
  fetchProjects = 'fetchProjects',
  deleteUser = 'deleteUser',
  fetchAllClients = 'fetchAllClients',
  fetchCompleteProjects = 'fetchCompleteProjects',
  addUsersToProject = 'addUsersToProject',
  fetchProjectTeam = 'fetchProjectTeam',
  completeProject = 'completeProject',
  createClient = 'createClient',
  deleteProject = 'deleteProject',
  fetchProject = 'fetchProject',
  fetchClientProjects = 'fetchClientProjects',
  fetchClient = 'fetchClient',
  updateClient = 'updateClient',
  createProjects = 'createProjects',
  fetchProjectDirections = 'fetchProjectDirections',
  editProjects = 'editProjects'
}
