


























import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import ContextPicker from '@/modules/settings/components/context-picker/ContextPicker.vue'
import TeamsModule from '@/store/modules/teams/TeamsModule';
import { IUser } from '../settings/views/knowledge/model';
import { IShortUser } from '@/models/user';

@Component({
    name: 'AddToTeamPopUp',
    components: { ContextPicker },
})
export default class Editor extends Vue {
    @Prop() value?: Array<IShortUser>
    @Prop() mode: string;

    
    public currentUsersPage = 2;
    public isLoading = false;
    public remoteTeams = Array<IShortUser>()
    public firstSearchUsers = false;
    mounted()
    {
        this.fetchTeams()
        this.remoteTeams = this.value
    }
    searchUsers(value: {page: number, search: string})
    {
        this.firstSearchUsers = true;
        this.currentUsersPage = 1;
        TeamsModule.clearTeams();
        this.loadUsers(value)
    }

    setUsers(users: Array<IUser>) 
    {
        this.remoteTeams = users;
    }
    
    @Emit('change')
    saveProjectTeams()
    {
        return this.remoteTeams
    }

    paginateUsers(value: {page: number, search: string})
    {
        if(this.firstSearchUsers)
        {
            this.currentUsersPage++;
            value.page = this.currentUsersPage
        }
        this.loadUsers(value);
        this.firstSearchUsers = false
        this.currentUsersPage++;
    }

    @Emit('close')
    closePopup()
    {
        return true;
    }

    closeAddToTeamPopup()
    {
        this.closePopup()
    }

    async loadUsers(payload?: {page: number, search: string}) {
        this.isLoading = true;
        await TeamsModule.fetchTeams(payload).finally(() => 
        {
            this.isLoading = false;
        });
    }
    async fetchTeams()
    {
        if(!TeamsModule.teams.length)
        {
            await TeamsModule.fetchTeams()
        }
    }
    
    get teams()
    {
        return TeamsModule.teams.map(el => {
            return { id: el.id, title: `${el.first_name} ${el.last_name}`, img: el.avatar };
        });
    }
    beforeDestroy()
    {
        TeamsModule.clearTeams();
    }
}
