


































































import ProjectCard from '@/components/documents/projects-stack/ProjectCard.vue';
import { ClientsResponse } from '@/models/dto/responses/clients.response';
import ProjectEditor from '@/modules/projects/ProjectEditor.vue'
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import ProjectTeamList from '@/modules/projects/components/ProjectTeamList.vue';
import ProjectsModule from '@/store/modules/projects/ProjectsModule';
import Notify from '@/services/helpers/notify';
import { IClientSettings } from '@/models/newProject';

@Component({
    components: {
        ProjectCard,
        ContextMenuLink,
        ContextMenuSpawner,
        ProjectEditor,
        ProjectTeamList,
        Icon,
    },
})
export default class ProjectsStack extends Vue {
    @Prop() client: ClientsResponse;
    @Prop() direction: ClientsResponse;

    public scroll = false;
    public isEditorOpen = false;
    public clientOptionsVisible = false;
    public items = [
        {
            id: 5,
            icon: {
                iconColor: '#fff',
                iconName: 'addtoteam',
            },
            title: 'Добавить в команду',
            value: 'addtoteam',
        },
        {
            id: 6,
            icon: {
                iconColor: '#fff',
                iconName: 'edit',
            },
            title: 'Редактировать',
            value: 'edit',
        },
        {
            id: 7,
            icon: {
                iconColor: '#fff',
                iconName: 'done',
            },
            title: 'Завершить',
            value: 'done',
        },
        {
            id: 8,
            icon: {
                iconColor: '#F76747',
                iconName: 'delete',
            },
            title: 'Удалить навсегда',
            value: 'delete',
        },
    ];

    public projectEdit = {
        general_chat: {
            title: 'Общий чат',
            subtitle: 'Помимо чатов проектов у вас будет общий чат по клиенту.',
            isActive: true,
        },
        general_tasks: {
            title: 'Общие задачи',
            subtitle: 'Помимо задач по проектам у вас будут общие задачи по&nbsp;клиенту.',
            isActive: true,
        },
        general_knowledges: {
            title: 'Общие знания',
            subtitle: 'Помимо знаний по проектам у вас будут общие знания по&nbsp;клиенту.',
            isActive: true,
        },
    }
    clientOrProjectPath(id: number)
    {
        return this.$route.query.direction === 'tags' ? this.$router.push(`/projects/${id}?filter=knowledge`) : this.$router.push(`/projects/client/${id}`)
    }
    closeClientOptions() {
        this.clientOptionsVisible = false;
    }
    async updateProject(clientSettings: IClientSettings)
    {   
        await ProjectsModule.updateClient({clientId: this.client.id, clientSettings: clientSettings}).then(() => 
        {
            Notify.success('Клиент обновлен')
            
        }).finally(() => 
        {
            this.updateCards()
            this.isEditorOpen = false;
        })
    }
    

    @Emit('updateProjects')
    updateCards()
    {
        return true
    }

    editClientVisible()
    {
        this.isEditorOpen = !this.isEditorOpen
    }
    toggleClientOptions() {
        this.clientOptionsVisible = !this.clientOptionsVisible;
    }
    itemClicked(item: object) {
        console.log(item);
    }
}
